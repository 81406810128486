@import url('https://fonts.googleapis.com/css?family=Titillium+Web');

*{
  padding: 0;
  margin: 0;
}

h1{
  letter-spacing: 5px;
  font-weight: 100;
  font-size: 4h;
  text-align: center;
  padding-top: 50px;
}

h2{
  letter-spacing: 2.5px;
  font-weight: 100;
  font-size: 3rem;
  color: #fc9403;
  text-decoration: underline;
}

h3{
  letter-spacing: 2px;
  font-weight: 300;
  text-align: center;
}

body{
  font-family:'Courier New', sans-serif;
  line-height: 1.4;
  background-color:#212529;
  color: #fff;
  height: 100%;
}

main{
  display: inline-block;
}

a, a:hover{
  color: #fc9403;
  text-decoration: none;
}

button{
  color: #fc9403;
  background-color: #000;
  text-decoration: none;
  cursor: pointer;
  border-radius: 25px;
  letter-spacing: 2px;
  font-size: 1.5rem;
  padding: 10px 30px;
  margin: 30px 42vw;
  transition-duration: 0.7s;
  
}

button:hover{
  background-color: #fc9403;
  color: #000;
  transition-duration: 0.7s;

}

.jumbotron-container{
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
}

.avatar{
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding:20px;
}

.information-collection{
  padding: 12vh 0;
  font-size: 2.5vh;
}

marquee{
  padding: 30px;
  width: 50%
}

::-webkit-scrollbar { 
  display: none; 
} 

@media only screen and (max-width: 768px) {
  marquee{
    width: 50%;
  }

  button{
    margin: 0;
  }
}
